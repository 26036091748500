var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _vm.isLoading && !_vm.input
        ? _c("div", [_c("Loading")], 1)
        : _c(
            "div",
            {
              staticStyle: { overflow: "scroll" },
              attrs: { id: "body-content-area-all" },
            },
            [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  {
                    staticClass: "has-text-centered heading",
                    style: {
                      backgroundColor: "#ededed",
                      marginBottom: "0px",
                    },
                  },
                  [
                    _c(
                      "h1",
                      {
                        staticClass: "is-size-6",
                        staticStyle: { padding: "10px 0px" },
                      },
                      [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    style: {
                      padding:
                        _vm.activeLayout === "administrative" ? "50px" : "25px",
                      paddingTop:
                        _vm.activeLayout === "administrative" ? "0px" : "0px",
                    },
                    attrs: { id: "pagelayout" },
                  },
                  [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.handleSubmitLocal()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "columns",
                              _vm.activeLayout === "administrative"
                                ? "is-centered"
                                : "",
                            ],
                          },
                          [
                            _c(
                              "div",
                              {
                                class: [
                                  "column is-full-tablet",
                                  _vm.activeLayout === "administrative"
                                    ? "is-three-fifths-desktop"
                                    : "is-two-thirds-desktop",
                                ],
                                style: {
                                  marginLeft:
                                    _vm.activeLayout === "administrative"
                                      ? _vm.isIE
                                        ? "30%"
                                        : "32%"
                                      : "",
                                  marginRight:
                                    _vm.activeLayout === "administrative"
                                      ? _vm.isIE
                                        ? "50%"
                                        : "auto"
                                      : "0!important",
                                  paddingTop:
                                    _vm.activeLayout !== "administrative"
                                      ? "0!important"
                                      : "",
                                },
                              },
                              [
                                _c("div", { staticClass: "custom-container" }, [
                                  _c("div", { staticClass: "third" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field is-grouped",
                                        staticStyle: {
                                          "padding-right": ".5rem",
                                          width: "100%",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            staticStyle: { width: "49%" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "label is-size-7",
                                              },
                                              [_vm._v("First Name")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.input.firstName,
                                                  expression: "input.firstName",
                                                },
                                              ],
                                              staticClass: "input",
                                              attrs: {
                                                type: "text",
                                                name: "firstName",
                                              },
                                              domProps: {
                                                value: _vm.input.firstName,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.input,
                                                    "firstName",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            staticStyle: { width: "49%" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "label is-size-7",
                                              },
                                              [_vm._v("Last Name")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.input.lastName,
                                                  expression: "input.lastName",
                                                },
                                              ],
                                              staticClass: "input",
                                              staticStyle: {
                                                "max-width": "302px!important",
                                              },
                                              attrs: {
                                                type: "text",
                                                name: "lastName",
                                              },
                                              domProps: {
                                                value: _vm.input.lastName,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.input,
                                                    "lastName",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field is-grouped",
                                        staticStyle: {
                                          "padding-right": ".5rem",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "label is-size-7",
                                              },
                                              [_vm._v("Email")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.input.email,
                                                  expression: "input.email",
                                                },
                                              ],
                                              staticClass: "input",
                                              attrs: {
                                                type: "text",
                                                name: "email",
                                              },
                                              domProps: {
                                                value: _vm.input.email,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.input,
                                                    "email",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field is-grouped",
                                        staticStyle: {
                                          "padding-right": ".5rem",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "label is-size-7",
                                              },
                                              [_vm._v("Title")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.input.title,
                                                  expression: "input.title",
                                                },
                                              ],
                                              staticClass: "input",
                                              attrs: {
                                                type: "text",
                                                name: "title",
                                              },
                                              domProps: {
                                                value: _vm.input.title,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.input,
                                                    "title",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.clientSession.role == "Team Member"
                                      ? _c(
                                          "div",
                                          { staticClass: "field is-grouped" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "label is-size-7",
                                              },
                                              [_vm._v("Bio")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "control" },
                                              [
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.input.bio,
                                                      expression: "input.bio",
                                                    },
                                                  ],
                                                  staticClass: "textarea",
                                                  domProps: {
                                                    value: _vm.input.bio,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.input,
                                                        "bio",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field is-grouped",
                                        staticStyle: {
                                          "padding-right": ".5rem",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "label is-size-7",
                                              },
                                              [_vm._v("Phone")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.input.phoneNumber,
                                                  expression:
                                                    "input.phoneNumber",
                                                },
                                              ],
                                              staticClass: "input",
                                              attrs: {
                                                type: "text",
                                                name: "phone",
                                              },
                                              domProps: {
                                                value: _vm.input.phoneNumber,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.input,
                                                    "phoneNumber",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row field is-grouped is-pulled-left",
                                    staticStyle: { width: "100%" },
                                  },
                                  [
                                    _c("p", { staticClass: "control" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button is-light",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.passwordConfirm.open()
                                            },
                                          },
                                        },
                                        [_vm._v("Update 2FA Preferences")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("Modal", {
                                  ref: "passwordConfirm",
                                  on: {
                                    close: function ($event) {
                                      return _vm.clearPasswordInputs()
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "header",
                                      fn: function () {
                                        return [_vm._v("Confirm your password")]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "body",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                margin: "1rem 0rem",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Please enter your password to continue "
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.password,
                                                    expression: "password",
                                                  },
                                                ],
                                                class: [
                                                  "input",
                                                  _vm.passwordErrors
                                                    ? "is-danger"
                                                    : "",
                                                ],
                                                attrs: { type: "password" },
                                                domProps: {
                                                  value: _vm.password,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.password =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "footer",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "modal-footer" },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  ref: "saveButton",
                                                  class: [
                                                    "button is-accent",
                                                    _vm.isPasswordConfirming
                                                      ? "saving"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    type: "button",
                                                    disabled:
                                                      _vm.isPasswordConfirming,
                                                  },
                                                  on: {
                                                    click: _vm.confirmPassword,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.isPasswordConfirming
                                                          ? "Confirming"
                                                          : "Confirm"
                                                      ) +
                                                      " "
                                                  ),
                                                  _vm.isPasswordConfirming
                                                    ? _c("spin-loader")
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("div", { staticClass: "custom-container" }, [
                                  _c("div", { staticClass: "third" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field is-grouped",
                                        staticStyle: {
                                          "padding-right": ".5rem",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "label is-size-7",
                                              },
                                              [_vm._v("Old Password")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.oldPassword,
                                                  expression: "oldPassword",
                                                },
                                              ],
                                              staticClass: "input",
                                              attrs: {
                                                type: "password",
                                                name: "phone",
                                              },
                                              domProps: {
                                                value: _vm.oldPassword,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.oldPassword =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field is-grouped",
                                        staticStyle: {
                                          "padding-right": ".5rem",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "label is-size-7",
                                              },
                                              [_vm._v("New Password")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.newPassword,
                                                  expression: "newPassword",
                                                },
                                              ],
                                              staticClass: "input",
                                              attrs: {
                                                type: "password",
                                                name: "phone",
                                              },
                                              domProps: {
                                                value: _vm.newPassword,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.newPassword =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "field is-grouped",
                                        staticStyle: {
                                          "padding-right": ".5rem",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            staticStyle: { width: "100%" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "label is-size-7",
                                              },
                                              [_vm._v("Verify New Password")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.newPassword2,
                                                  expression: "newPassword2",
                                                },
                                              ],
                                              staticClass: "input",
                                              attrs: {
                                                type: "password",
                                                name: "phone",
                                              },
                                              domProps: {
                                                value: _vm.newPassword2,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.newPassword2 =
                                                    $event.target.value
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "twothird" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "is-size-7 is-pulled-left",
                                      },
                                      _vm._l(
                                        _vm.passwordValidation,
                                        function (item) {
                                          return _c(
                                            "div",
                                            {
                                              key: item.msg + item.isValid,
                                              staticStyle: {
                                                width: "100%!important",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                class: item.isValid
                                                  ? "far fa-check-circle has-text-accent"
                                                  : "fas fa-times has-text-danger",
                                              }),
                                              _vm._v(
                                                ": " + _vm._s(item.msg) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row field is-grouped is-pulled-left",
                                    staticStyle: { width: "100%" },
                                  },
                                  [
                                    _c("p", { staticClass: "control" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "button is-light",
                                          on: {
                                            click: function ($event) {
                                              _vm.resetForm()
                                              _vm.$router.go(-1)
                                            },
                                          },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                    ]),
                                    _vm._m(0),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "control" }, [
      _c(
        "button",
        {
          staticClass: "button is-accent has-text-white",
          attrs: { type: "submit" },
        },
        [_vm._v("Save")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }